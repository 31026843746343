<template>
  <div class="dealOrder">
    <el-dialog title="订单处理" :visible.sync="approvals.isShow" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
        <!-- 审批意见 -->
        <el-form-item label="审批意见"  prop="approval" class="approval"> 
          <el-radio v-model="ruleForm.radio" label="1">同意</el-radio>
          <el-radio v-model="ruleForm.radio" label="2">不同意，驳回</el-radio>
        </el-form-item>
        <!-- 文本域 -->
        <el-form-item class="textArea">
          <el-input type="textarea" placeholder="请输入审批意见" v-model="ruleForm.textarea"
          maxlength="200" show-word-limit :row="10">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="approvals.isShow = false">确 定</el-button>
  </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:["approvals"],

  data() {
    return {
      ruleForm:{
        radio:'1',
        textarea:''
      },
      rules:{
        approval:[
          { required: true, message: '请选择审批意见', trigger: 'change' }
        ]
      }
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style scoped>
.dealOrder /deep/ .el-dialog{
  font-family: 'SHSCN_Regular';
}
.dealOrder  /deep/ .el-dialog__title{
  font-family: 'SHSCN_Bold';
}
.dealOrder /deep/ .el-form{
  margin: 0;
}
.dealOrder /deep/ .el-dialog__body{
  padding: 20px 20px;
}
.approval{
  display: flex;
  flex-direction: column
}
.approval /deep/ .el-form-item__label{
  font-family: 'SHSCN_Bold';
  text-align: left;
}
.approval /deep/ .el-form-item__content{
  margin-left: 2vw;
}
.textArea /deep/ .el-textarea{
  width: 90%;
  height: 15vh;
  margin-left: 2vw;
}
.textArea /deep/ .el-textarea__inner{
  height: 15vh;
  resize: none;
}
</style>