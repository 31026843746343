<template>
  <!-- 采购详情 -->
  <div class="purchase_detailed">
    <!-- 板块中心区域 -->
    <div class="purchase_mid">
      <div class="purchase_header">
        <!-- 标题 -->
        <div class="purchase_title">
          <p>编号:{{inform.ordersNumber}}</p>
          <div class="gobackImg">
            <img src="../../../assets/image/user_detail/group_140.png" alt="" @click="goback">
          </div>
        </div>
        <div class="agentDetail_content">
          <p class="number">营业执照号<span>231235128412369825</span></p>
          <p>公司名称<span>{{inform.ordersClients}}</span></p>
          <p>代理负责人<span>李四</span></p>
          <p>联系电话<span>13800001252</span></p>
          <p>
            合约状态
            <span v-if="inform.contractStatus == true ">已签署</span>
            <span v-else>未签署</span>
          </p>
          <p>采购日期<span>{{inform.purchaseData}}</span></p>
          <p>发货状态
            <span v-if="inform.ordersStatus == '0'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_40.png" alt="" style='margin-right:0.2vw'>已完成
            </span>
            <span v-else-if="inform.ordersStatus == '1'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_38.png" alt="" style='margin-right:0.2vw'>待发货
            </span>
            <span v-else-if="inform.ordersStatus == '2'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_37.png" alt="" style='margin-right:0.2vw'>待处理
            </span>
            <span v-else-if="inform.ordersStatus == '3'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_38.png" alt="" style='margin-right:0.2vw'>处理中
            </span>
            
          </p>
          <p>所属地区<span>江苏省南通市</span></p>
          <p class="deMap">详细地址<span>江苏省南通市通州区新世纪大道998号</span></p>
        </div>
      </div>
      <!-- 表格区域 -->
    <div class="purchase_form">
      <!-- 订单详细 -->
      <div class="order_title">
        <h4>订单详细</h4>
        <div class="order_btn">
          <el-button style="background-color:#0666F5;color:#fff" @click="edit" id="edits">编辑</el-button>
          <el-button style="background-color:#C1C3C7;color:#fff" @click="dealWith" id="dealWiths">订单处理</el-button>
        </div>
      </div>
      <!-- 基本信息 -->
      <div class="basic_inform">
        <div class="basic_title">
          <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
          <h5>基本信息</h5>
        </div>
        <!-- 表单区域 -->
        <el-form ref="form" :model="form" label-width="80px">
          <!-- 代理商 -->
          <el-form-item label="代理商" >
            <el-input v-model="form.agent" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 订单客户 -->
          <el-form-item label="订单客户">
            <el-input v-model="form.orderClient" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 收件人 -->
          <el-form-item label="收件人">
            <el-input v-model="form.addressee" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 联系电话 -->
          <el-form-item label="联系电话">
            <el-input v-model="form.tel" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 详细地址 -->
          <el-form-item label="详细地址" class="detailAdress">
            <el-cascader size="large"
                :options="optionsDetail"
                v-model="form.province"
                @change="handleChangeDetail" style="width:100%;">
              </el-cascader>
          </el-form-item>
          <!-- 详细地址框 -->
          <el-form-item>
            <el-input v-model="form.addressDetail"  suffix-icon="el-icon-map-location" class="address" :disabled="disabled"></el-input>
            <div class="map_btn" @click="isMap.isshow = true"></div>
            <!-- 地图 -->
            <el-dialog width="40%" title="地图" :visible.sync="isMap.isshow" append-to-body :close-on-click-modal="false">
              <!-- 搜索页面 -->
              <div id="search_ipt">
                <searchs></searchs>
              </div>
              <!-- map地图页面 -->
              <maps :isMap="isMap"></maps>
              <div slot="footer" class="dialog-footer">
                <el-button @click="isMap.isshow = false">取 消</el-button>
                <el-button type="primary" @click="isMap.isshow = false">确定</el-button>
              </div>
            </el-dialog>
          </el-form-item>
          <!-- 快递公司 -->
          <el-form-item label="快递公司" class="delivery">
            <el-select v-model="form.value1"  placeholder="请选择快递公司" :disabled="disabled">
              <el-option :label="item.label" :value="item.value" v-for="item in form.courierCompanies" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- 快点单号 -->
          <el-form-item label="快点单号" class="deliveryNum">
            <el-input v-model="form.name" suffix-icon="el-icon-copy-document" :disabled="disabled"></el-input>
            <el-button style="background-color:#0666F5;color:#fff">发货 </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 采购信息 -->
      <div class="purchase_inform">
        <div class="purchase_inform_title">
          <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
          <h5>采购信息</h5>
        </div>
        <el-form ref="form" :model="forms" label-width="80px">
          <!-- 关联项目 -->
          <el-form-item label="关联项目">
            <el-input v-model="forms.linkProject" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 生效日期 -->
          <el-form-item label="生效日期">
            <el-date-picker v-model="forms.value" type="date" placeholder="选择日期" :disabled="disabled">
            </el-date-picker>
          </el-form-item>
          <!-- 采购金额 -->
          <el-form-item label="采购金额">
            <el-input v-model="forms.purchaseSum" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 网关数量 -->
          <el-form-item label="网关数量">
            <el-input v-model="forms.gatewayNumber" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 回路数量 -->
          <el-form-item label="回路数量">
            <el-input v-model="forms.loopNumber" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 塑壳数量 -->
          <el-form-item label="塑壳数量">
            <el-input v-model="forms.moldedShellNumber" :disabled="disabled"></el-input>
          </el-form-item>
          <!-- 空--占位 -->
          <el-form-item >

          </el-form-item>
          <!-- 空--占位 -->
          <el-form-item>

          </el-form-item>
          <!-- 采购合同 -->
          <el-form-item label="采购合同" class="agreement">
            <img src="#" alt="">
          </el-form-item>
        </el-form>
        <!-- 意见审批 -->
      <dealorder :approvals="approvals"></dealorder>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// 引入审批意见窗口
import dealorder from "./dealOrder.vue";
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
import {CodeToText,regionDataPlus  } from 'element-china-area-data';
import { msg } from "../../../common/public";
// 引入地图和输入框
import maps from "../../customerInformation/components/map.vue";
import searchs from "../../customerInformation/components/search.vue";
export default {
  components: {
    dealorder,//意见审批
    maps,
    searchs,
  },
  data () {
    return {
      isMap:{
        isshow:false
      },
      optionsDetail:regionDataPlus,//城市县信息
      inform:'',//采购信息
      forms:{
        linkProject:'*****代理合同',//关联项目
        value:'',//日期值
        purchaseSum:'100万',//购买金额
        gatewayNumber:1,//网关数量
        loopNumber:1,//回路数量
        moldedShellNumber:1,//塑壳数量
      },
      disabled:false,
      approvals:{ //审批意见的窗口是否展示
        isShow:false
      }
    };
  },
  computed: {
    ...mapState('purchaseDetail',['form'])
  },
  mounted () {
    let informs = JSON.parse(sessionStorage.getItem('informs'));
    this.inform = informs;
  },

  methods: {
    // 省市县多级选择器
    handleChangeDetail(){
      let location = '';//获取选择的地址
      if (this.form.province[0] == '') { //说明第一项选择了全部  提醒选择详细地址
        msg('请选择你所在省','error');
        return false;
      }
      if (this.form.province[1] == '') {
        msg('请选择你所在市的详细地址','error')
        return false;
      }
      if (this.form.province[2] == '') {
        msg('请选择你所在区/县的详细地址','error')
        return false;
      };
      // 将地区码转话为地址
      for (let i = 0; i < this.form.province.length; i++) {
        location += CodeToText[this.form.province[i]];
      }
      console.log(location);
    },
    goback(){
      this.$router.go(-1)
    },
    // 编辑
    edit(){
      let editsBtn = document.querySelector('#edits');
      let dealWithBtn = document.querySelector('#dealWiths');
      editsBtn.style.backgroundColor = "#0666F5";
      dealWithBtn.style.backgroundColor = "#C1C3C7";
      this.disabled = false
    },
    dealWith(){
      let editsBtn = document.querySelector('#edits');
      let dealWithBtn = document.querySelector('#dealWiths');
      editsBtn.style.backgroundColor = "#C1C3C7";
      dealWithBtn.style.backgroundColor = "#0666F5";
      this.disabled  = true;
      // 审批意见窗口是否展示
      this.approvals.isShow = true;
    }

  },
};
</script>

<style  scoped>
  /* 地图输入框的样式 */
#search_ipt{
  position: absolute;
  z-index: 999;
  /* border: 1px solid #333; */
  position: absolute;
  top: 100px;
  left: 38px;
}
.map_btn{
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 100px;
  cursor: pointer;
}
/* 地图小图标 */
.address /deep/ .el-input__icon{
  color: #0666F5;
}
.purchase_form{
  height: 90%;
  font-family: 'SHSCN_Regular';
}
.purchase_detailed .purchase_mid {
  width: 82.5vw;
  height: 85vh;
  /* overflow-y: scroll; */
}

/* 头部--开始 */
.purchase_mid .purchase_header {
  width: 100%;
  height: 176px;
  background-color: #fff;
}
.purchase_title{
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vh 1.5% 0 1.5%;
}
.purchase_title > p{
  font-family: "SHSCN_Bold";
  font-size: 20px;
}
/* 头部--详细地址 */
.deMap{
  width: 200%;
}
.agentDetail_content{
  height: calc(100% - 35%);
  padding: 0 0.5% 0 1.5%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  align-items: center;
}
.agentDetail_content p{
  font-family: "SHSCN_Regular";
  font-size: 14px;
  color:#ABABAB; 
}
.agentDetail_content span{
  color: #171717;
  margin: 0 0 0 4%;
}
/* 表单区域 */
.purchase_mid .purchase_form {
  width: 100%;
  background-color: #fff;
  margin-top: 2vh;
}

/* 订单详情 */
.order_title{
  display: flex;
  justify-content: space-between;
}
.order_title>h4{
  font-size:18px;
  font-family: 'SHSCN_Bold';
  margin: 28px 0 0 28px;
}
.order_title>.order_btn{
  margin: 28px 28px 0 0;
}
/* button按钮的大小 */
.order_btn /deep/ .el-button {
  width: 78px;
  height: 32px;
  padding: 0;
}
/* 基本信息--开始 */
/* 基本信息标题 */
.basic_title{
  display: flex;
  margin-top: 4.27vh;
}
.basic_title img{
  margin: 0 0.42vw 0 1.88vw;
}
.basic_title h5{
  font-size: 16px;
  font-family: 'SHSCN_Regular';
  color: #171717;
}
/* 表单区域 */
.basic_inform .el-form{
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
}
::v-deep .el-form{
  margin: 3.85vh 0 0 2.47vw;
}
/* 快递公司 */
.delivery /deep/ .el-form-item__content{
  margin-left: 4.5vw !important;
}
.delivery /deep/ .el-input__icon{
  line-height: 2vh;
}
/* 快递单号 */
.deliveryNum /deep/ .el-form-item__content{
  display: flex;
}
.deliveryNum /deep/ .el-input{
  margin-right: 0.5vw;
}

/* 采购信息 */
.purchase_inform_title{
  display: flex;
  margin-top: 4.27vh;
}
.purchase_inform_title img{
  margin: 0 0.42vw 0 1.88vw;
}
.purchase_inform_title h5{
  font-size: 16px;
  font-family: 'SHSCN_Regular';
  color: #171717;
}
.purchase_inform .el-form{
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
}
.agreement img{
  width: 5vw;
  height: 4vh;
  background-color: #ababab;
}




/* 多媒体查询 */
/* 小于1920 */
@media screen and (max-width: 1920px){
  .delivery /deep/ .el-form-item__content{
  margin-left: 80px !important;
}
}
</style>